import * as React from 'react'
import { useState } from 'react'
import USD, { USD_P2 } from '../helpers/NumberHelper'
import CategoryImage from './CategoryImage'
import IconsCheckout, {
  IconPayPal,
  IconAffirm,
  IconBank,
  IconCoin,
  IconCreditCard,
} from './IconsCheckout'
import PropTypes from 'prop-types'
import useMediaQuery from '../hooks/useMediaQuery'
import NewArrivalSlider from './sliders/NewArrivalSlider'
import finalPrice from '../helpers/product/finalPrice'

const NoResultsItemsPerScreen = step => {
  const isLg = useMediaQuery('(min-width: 64em)')
  const isXl = useMediaQuery('(min-width: 80em)')
  const theStep = (step || 0) <= 0 ? Number.MAX_SAFE_INTEGER : step

  const itemsPerScreen = React.useCallback(() => {
    if (isXl) return Math.min(2, theStep)
    if (isLg) return Math.min(1, theStep)
    return Math.min(2, theStep)
  }, [isXl, isLg, theStep])

  return [itemsPerScreen, 'w-1/2 lg:w-full xl:w-1/2']
}

const CartItemCard = ({ product, onClick }) => {
  return (
    <div className={'relative flex flex-col gap-5 '}>
      <div
        className={
          'border-gray-300  duration-300 flex flex-row w-full px-2 border-b print:border-none py-4'
        }
      >
        <div onClick={onClick} className={'w-1/4 sm:w-1/3 max-w-[8rem] aspect-square m-auto'}>
          <CategoryImage
            normal={product.image}
            sku={product.sku}
            title={product.title}
            alt={product.name}
            objectFit={'cover'}
            placeholder={'none'}
            loading={'eager'}
            className=""
            productType={product.productType}
            sizes="(min-width: 48em) 30em, 33vw"
          />
        </div>
        <div className="w-full text-xs grid grid-cols-[auto_1fr] gap-x-3 grid-rows-[auto_auto_1fr] justify-around text-left">
          <p className="text-gray-600 text-right">Product: </p>
          <div className={'  text-sm line-clamp-2 max-h-10'}>{product.title}</div>
          <p className="text-gray-600 text-right">Sku No.: </p>
          <div className={' text-sm line-clamp-2'}>{product.sku}</div>
          <p className="text-gray-600 text-right">Price: </p>
          <div className="flex justify-start w-full ">{USD(finalPrice(product))}</div>
        </div>
      </div>
    </div>
  )
}
CartItemCard.propTypes = {
  product: PropTypes.object,
  onClick: PropTypes.func,
  large: PropTypes.string,
  error: PropTypes.oneOfType([
    PropTypes.shape({
      message: PropTypes.string,
    }),
    PropTypes.string,
  ]),
}

const ThankYouShoppingSummary = ({ order, isReloading }) => {
  const products = order.products

  const [largeViewSku, setLargeViewSku] = useState('')
  React.useEffect(() => {
    if (products.length > 0) {
      setLargeViewSku(products[0].sku)
    }
  }, [products.length])

  // products.forEach(product => {
  //   console.log('product', product.product.data.attributes)
  //   product.product.data.attributes.price = product.price
  //   product.product.data.attributes.qty = product.qty
  //   // product.product.data.attributes.image = product.product.data.attributes.image.data
  // })

  return (
    <section
      id="ThankYouShoppingSummary"
      className={
        'relative top-0 order-1 border print:border-none mt-5 md:border-none md:pb-0 w-full mx-auto max-w-[62.5rem]'
      }
    >
      <IconsCheckout />
      <section className=" py-2 text-left text-sm md:text-base flex flex-row border print:border-none print:border-b px-2 md:p-4 justify-between flex-wrap md:flex-nowrap">
        <div className="w-1/2 pr-3 lg:w-full md:max-w-max border-r-2">
          <h2 className="font-bold ">Shipping Details</h2>
          {order.shipping.name} {order.shipping.lastName}
          <br />
          {order.shipping.address}
          {order.shipping.address2}
          <br />
          {order.shipping.city}, {order.shipping.state} {order.shipping.zip}
          <br />
          {order.shipping.email}
          <br />
          {order.shipping.phone}
        </div>

        <div
          className={
            'order-3 md:order-2 w-full pt-4 md:pt-0 md:px-4  ' +
            (order.paymentStatus !== 'paid' && order.paymentMethod !== 'BankWire'
              ? 'text-red-700'
              : 'text-green-600')
          }
        >
          <hr className="md:hidden bg-gray-200" />
          <h2 className="font-bold ">Payment Method</h2>

          <div className="  w-full ">
            {order.paymentMethod === 'CreditCard' && (
              <nobr className=" ">
                <IconCreditCard /> Credit Card
              </nobr>
            )}
            {order.paymentMethod === 'PayPal' && (
              <nobr>
                <IconPayPal /> PayPal
              </nobr>
            )}
            {order.paymentMethod === 'Affirm' && (
              <nobr>
                <IconAffirm className={'w-16 h-auto mb-2'} />
                Affirm
              </nobr>
            )}
            {order.paymentMethod === 'BankWire' && (
              <nobr>
                <IconBank /> Wire Transfer
              </nobr>
            )}
            {order.paymentMethod === 'BitPay' && (
              <div className="w-full">
                <IconCoin className={'fill-current w-5 h-5 '} /> BitPay
              </div>
            )}
          </div>
          {order.paymentMethod === 'BankWire' ? (
            <b>
              Thanks for your order. One of our specialists will be contacting you shortly with Bank
              Wire transfer instructions.
            </b>
          ) : (
            <div>
              Payment status:{' '}
              {isReloading ? (
                <div className="w-full bg-gray-100 h-6 skeleton" />
              ) : (
                <>
                  {order.paymentStatus}
                  <br />
                  {order.paymentStatus === 'declined' && (
                    <b className="text-red-500">
                      Your payment was declined. Would you like to try a different Payment Method?
                    </b>
                  )}
                  {order.paymentStatus !== 'paid' && order.paymentStatus !== 'declined' && (
                    <b className="text-red-500">
                      Your payment is being Processed but not yet completed at this time.
                    </b>
                  )}
                </>
              )}
            </div>
          )}
        </div>

        {/* <hr className="my-3 w-full md:hidden " /> */}
        <div
          className={
            ' order-2 md:order-3 w-1/2 lg:w-full flex flex-col md:cursor-default md:max-w-max  print:border-b print:pb-4 min-w-max max-w-max md:border-l-2 md:pl-3 '
          }
        >
          <div className={' grid grid-cols-2 odd:text-left event:text-right min-w-max  '}>
            <p>Subtotal: &ensp;</p>
            <p className="text-right text-gray-500">{USD_P2(order.subtotal)}</p>
            <div>Shipping: </div>
            <div
              className={
                (order?.coupon?.free_shipping === true && 'line-through') +
                ' flex justify-end text-gray-500'
              }
            >
              <p className="text-right"> {USD_P2(order.shippingCost)} </p>
            </div>
            <div>Sales Tax:</div>
            <div className="flex justify-end text-gray-500">
              <p>{USD_P2(order.tax)}</p>
            </div>

            {order.coupon && order?.coupon?.discount > 0 ? (
              <>
                <div>Coupon Discount: &ensp; </div>
                <div className="text-right text-red-600">-{USD_P2(order?.coupon?.discount)}</div>
              </>
            ) : null}

            {order.surcharge && order.surcharge > 0 ? (
              <>
                <div>Surcharge:</div>
                <p className="text-right text-gray-500">{USD_P2(order.surcharge)}</p>
              </>
            ) : null}
            <p className="font-bold">TOTAL: </p>
            <p className="text-right font-bold">{USD_P2(order.total)}</p>
          </div>
        </div>
      </section>
      <div className="grid grid-cols-2 w-full ">
        <div
          className={
            'text-sm md:text-base w-full cursor-default relative col-span-2 md:col-span-1 '
          }
        >
          {products.map(product => (
            <CartItemCard
              product={product}
              onClick={() => setLargeViewSku(product.sku)}
              key={product.sku}
              large={largeViewSku}
            />
          ))}
        </div>
        <div className="col-span-2 md:col-span-1 w-full">
          <div className="w-full pt-5 flex justify-center items-center print:hidden">
            <NewArrivalSlider
              itemsPerScreenFactory={NoResultsItemsPerScreen}
              className="w-full mt-5 md:mt-0"
            />
          </div>
        </div>
      </div>
    </section>
  )
}
ThankYouShoppingSummary.propTypes = {
  order: PropTypes.shape({
    id: PropTypes.number,
    shipping: PropTypes.shape({
      name: PropTypes.string,
      lastName: PropTypes.string,
      address: PropTypes.string,
      address2: PropTypes.string,
      city: PropTypes.string,
      state: PropTypes.string,
      zip: PropTypes.string,
      email: PropTypes.string,
      phone: PropTypes.string,
    }),
    paymentMethod: PropTypes.string,
    subtotal: PropTypes.number,
    shippingCost: PropTypes.number,
    tax: PropTypes.number,
    total: PropTypes.number,
    discount: PropTypes.number,
    surcharge: PropTypes.number,
    products: PropTypes.array,
    paymentStatus: PropTypes.string,
  }),
  fetchOrder: PropTypes.func,
  isReloading: PropTypes.bool,
}

export default ThankYouShoppingSummary
